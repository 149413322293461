import { makeAutoObservable } from "mobx";
import { IRole } from "common/types/roles";
import { IGroupDetails } from "common/types/users-groups";
import {
  AppTheme,
  //DefaultTheme,
  // ThemedStyledComponentsModule,
} from "styled-components";
//import { blackMamba } from "styles/theme";
import { getThemePalette } from "styles/themes/blog-themes";
import { IUserData } from "common/types/user-data-config";

class AppConfigurationStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _defaultTeamAvatars: string[] = [];
  get defaultTeamAvatars(): string[] {
    return this._defaultTeamAvatars;
  }

  set defaultTeamAvatars(values: string[]) {
    this._defaultTeamAvatars = values;
  }

  private _defaultUserAvatars: string[] = [];
  get defaultUserAvatars(): string[] {
    return this._defaultUserAvatars;
  }

  set defaultUserAvatars(values: string[]) {
    this._defaultUserAvatars = values;
  }

  private _usersGroups: IGroupDetails[] = [];
  get usersGroups(): IGroupDetails[] {
    return this._usersGroups;
  }

  set usersGroups(values: IGroupDetails[]) {
    this._usersGroups = values;
  }

  private _roles: IRole[] = [];
  get roles(): IRole[] {
    return this._roles;
  }

  set roles(values: IRole[]) {
    this._roles = values;
  }

  private _appTheme: AppTheme = getThemePalette("blackMamba");
  get appTheme(): AppTheme {
    return this._appTheme;
  }

  set appTheme(values: AppTheme) {
    this._appTheme = values;
  }

  private _userDataConfig: IUserData | null = null;
  get userDataConfig(): IUserData | null {
    return this._userDataConfig;
  }

  set userDataConfig(values: IUserData | null) {
    this._userDataConfig = values;
  }

  private _historyPath: string | undefined = undefined;
  get historyPath(): string | undefined {
    return this._historyPath;
  }

  set historyPath(values: string | undefined) {
    this._historyPath = values;
  }
}
export const appConfigurationStore = new AppConfigurationStore();
